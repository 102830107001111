import React from "react"
import { navigate } from "gatsby"

import Layout from "../../components/admin/layout"
import Modal from "../../components/common/modal"
import request, { getUserRole, getUserData } from "../../request"

const blankAnnouncement = () => ({
  uid: -1,
  createdAt: new Date(),
  updatedAt: new Date(),
  title: "",
  content: "",
})

const fmtDate = dateStr => {
  const date = new Date(dateStr)
  let mm = date.getMonth() + 1 // getMonth() is zero-based
  let dd = date.getDate()
  let hh = date.getHours()
  let MM = date.getMinutes()
  let ss = date.getSeconds()

  mm = (mm > 9 ? "" : "0") + mm
  dd = (dd > 9 ? "" : "0") + dd
  hh = (hh > 9 ? "" : "0") + hh
  MM = (MM > 9 ? "" : "0") + MM
  ss = (ss > 9 ? "" : "0") + ss

  return `${date.getFullYear()}/${mm}/${dd} ${hh}:${MM}:${ss}`
}

class Announcement extends React.Component {
  state = {
    announcements: [],
    announcement: blankAnnouncement(),
    uidToName: {},
    userData: {},
    reveal: false,
  }

  newAnnouncement = () => {
    this.setState({ announcement: blankAnnouncement(), reveal: true })
  }

  componentDidMount() {
    if (getUserRole() === 0) return navigate("/admin/login")
    const promises = []
    promises.push(request.getUsers())
    promises.push(request.getAnnouncements({ _sort: "id:DESC" }))
    Promise.all(promises).then(values => {
      const uidToName = values[0].data.reduce((accu, curr) => {
        accu[curr.id] = curr.name
        return accu
      }, {})
      const announcements = values[1].data.filter(a => a.uid in uidToName)
      this.setState({
        announcements,
        uidToName,
        userData: getUserData(),
      })
    })
  }

  onCancel = () => {
    this.setState({ reveal: false })
  }

  onConfirm = () => {
    const { announcement } = this.state
    if (!announcement.content || !announcement.title) {
      return alert("所有欄位皆須填寫")
    }

    if (announcement.id) {
      request
        .putAnnouncement(announcement.id, {
          title: announcement.title,
          content: announcement.content,
          updatedAt: new Date(),
        })
        .then(res => {
          const announcements = this.state.announcements.map(a =>
            a.id === announcement.id ? res.data : a
          )
          this.setState({ announcements, reveal: false })
        })
    } else {
      request
        .postAnnouncement({
          title: announcement.title,
          content: announcement.content,
          updatedAt: new Date(),
          createdAt: new Date(),
          uid: this.state.userData.id,
        })
        .then(res => {
          this.setState({
            announcements: [res.data, ...this.state.announcements],
            reveal: false,
          })
        })
    }
  }

  onEdit = announcement => {
    this.setState({ announcement, reveal: true })
  }

  onDelete = id => {
    request.deleteAnnouncement(id).then(res => {
      this.setState({
        announcements: this.state.announcements.filter(a => a.id !== id),
      })
    })
  }

  render() {
    if (!getUserRole()) return null

    const {
      announcement,
      announcements,
      uidToName,
      userData,
      reveal,
    } = this.state

    return (
      <Layout>
        <div className="ad-announcement">
          <div className="ad-common-controls">
            <button onClick={this.newAnnouncement}>新增留言</button>
          </div>
          <hr />
          <br />

          {announcements.map(a => (
            <div className="ad-announcement__posts" key={a.id}>
              <div className="ad-announcement__post">
                <div className="ad-announcement__header">
                  <div className="ad-announcement__title">{a.title}</div>
                </div>
                <div className="ad-announcement__body">{a.content}</div>
                <div className="ad-announcement__footer">
                  <div className="ad-announcement__datetimes">
                    <div className="ad-announcement__datetime">
                      <div className="ad-announcement__label">
                        建立日期:&nbsp;
                      </div>
                      <div className="ad-announcement__value">
                        {fmtDate(a.createdAt)}
                      </div>
                    </div>
                    <div className="ad-announcement__datetime">
                      <div className="ad-announcement__label">
                        更新日期:&nbsp;
                      </div>
                      <div className="ad-announcement__value">
                        {fmtDate(a.updatedAt)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ad-announcement__author">
                  <div>
                    <span>留言作者:&nbsp;</span>
                    <span>{uidToName[a.uid]}</span>
                  </div>
                  <div
                    className={
                      userData.role.id === 1 || a.uid === userData.id
                        ? ""
                        : "hidden"
                    }
                  >
                    <button onClick={() => this.onEdit(a)}>編輯</button>&nbsp;
                    <button
                      onClick={() => {
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm("確定刪除此留言?")) {
                          this.onDelete(a.id)
                        }
                      }}
                    >
                      刪除
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Modal
            reveal={reveal}
            size="lg"
            header={announcement.id ? "編輯留言" : "新增留言"}
            onCancel={this.onCancel}
            onConfirm={this.onConfirm}
            textConfirm={announcement.id ? "更新" : "新增"}
          >
            <form className="ad-announcement__form">
              <div className="ad-announcement__form-item">
                <input
                  type="text"
                  placeholder="請輸入留言標題"
                  value={announcement.title}
                  onChange={e => {
                    this.setState({
                      announcement: {
                        ...announcement,
                        title: e.target.value,
                      },
                    })
                  }}
                />
              </div>
              <div className="ad-announcement__form-item">
                <textarea
                  placeholder="請在此輸入要發布的消息"
                  value={announcement.content}
                  onChange={e => {
                    this.setState({
                      announcement: {
                        ...announcement,
                        content: e.target.value,
                      },
                    })
                  }}
                />
              </div>
            </form>
          </Modal>
        </div>
      </Layout>
    )
  }
}

export default Announcement
